import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { Seminar } from '../../../../models/Seminar.model';
import { ApiResponse } from '../../../../models/api-response.model';

const URL_EVENTS = `${environment.event}/eventhub`;
const URL_FAVORITES = `${environment.event}/favorite`;

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  getEvents(pageSize: number = 0, pageNumber: number = 0): Observable<ApiResponse<Seminar[]>> {
    return this.http.get<ApiResponse<Seminar[]>>(`${URL_EVENTS}?PageIndex=${pageNumber}&PageSize=${pageSize}`, { withCredentials: true }).pipe(
      map(response => ({
        ...response,
        data: response.data.map(event => this.setEventProperties(event))
      }))
    );
  }

  private setEventProperties(event: Seminar): any {
    return {
      ...event,
      color: event.typeEvent === 2
        ? 'var(--color-secondary-50)'
        : 'var(--color-tertiary-50)',
      typeName: event.typeEvent === 2
        ? 'eventSintegra'
        : 'eventExternal'
    };
  }

  getFavoritedEvents() {
    return this.http.get<ApiResponse<Seminar[]>>(URL_FAVORITES, { withCredentials: true }).pipe(
      map(response => ({
        ...response,
        data: response.data.map(event => this.setEventProperties(event))
      }))
    );
  }

  getEventById(id: string) {
    return this.http.get<ApiResponse<Seminar>>(`${URL_EVENTS}/${id}`, { withCredentials: true }).pipe(
      map(response => ({
        ...response,
        data: this.setEventProperties(response.data)
      }))
    );
  }

  getEventRelatedContent(id: string) {
    return this.http.get<ApiResponse<string[]>>(`${URL_EVENTS}/content/${id}`, { withCredentials: true });
  }

  addToEventToFavorite(idFavorite: string) {
    return this.http.post<any>(URL_FAVORITES, { id: idFavorite }, { withCredentials: true });
  }

  deleteFavorited(id: string) {
    return this.http.delete<any>(`${URL_FAVORITES}/${id}`, { withCredentials: true });
  }

}
