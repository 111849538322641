import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MainComponent } from './components/main/main.component';
import { IonCol, IonGrid, IonIcon, IonRow, IonSkeletonText } from '@ionic/angular/standalone';
import { arrowForwardOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { forkJoin } from 'rxjs';

import { CardShortsComponent } from '../../components/card-shorts/card-shorts.component';
import { ContentService } from '../../services/api/ws-content/content/content.service';
import { CardNewsComponent } from '../../components/card-news/card-news.component';
import { CommonModule } from '@angular/common';
import { CategoryService } from '../../services/api/ws-common/categories/category.service';
import { CardEventComponent } from '../../components/card-event/card-event.component';
import { EventService } from '../../services/api/ws-event/event/event.service';
import { LocationService } from '../../services/api/ws-common/location/location.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Content } from '../../models/Content.model';
import { Category } from '../../models/ws-common/category.model';
import { Seminar } from '../../models/Seminar.model';
import { ApiResponse } from '../../models/api-response.model';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MainComponent, CardShortsComponent, IonIcon, CardNewsComponent, CommonModule, CardEventComponent, RouterModule, IonSkeletonText, TranslateModule, IonGrid, IonRow, IonCol],
  providers: [ContentService],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

  @ViewChild('categoryBackground') categoryBackground!: ElementRef;
  videos!: Content[];
  news!: Content[];
  categories!: Category[];
  events!: Seminar[];
  selectedCat!: Category;
  isLoaded = false;
  themeMode!: string;
  categoryAnimationChanged = false;

  constructor(private contentService: ContentService, private categoryService: CategoryService, private eventService: EventService, private locationService: LocationService, private toastService: ToastService) {
    addIcons({ arrowForwardOutline });
  }


  ngOnInit(): void {
    this.fetchVideos();
    this.fetchNews();
    this.fetchCategories();
    this.fetchEvents();

    this.categoryAnimation();
  }

  private fetchEvents() {
    this.eventService.getEvents().subscribe({
      next: (events) => {
        this.orderNextEvents(events);
      },
      error: (error) => {
        const errorMessage = typeof error === 'string' ? error : error.error.errors[0];
        this.toastService.displayToast(errorMessage, 'error');
      }
    })
  }

  private fetchVideos() {
    this.contentService.getVideos(6).subscribe({
      next: (videos) => {
        this.videos = videos.data;
      },
      error: (error) => {
        const errorMessage = typeof error === 'string' ? error : error.error.errors[0];
        this.toastService.displayToast(errorMessage, 'error');
      }
    })
  }

  private fetchNews() {
    this.contentService.getNews(5).subscribe({
      next: (news) => {
        this.news = news.data;
      },
      error: (error) => {
        const errorMessage = typeof error === 'string' ? error : error.error.errors[0];
        this.toastService.displayToast(errorMessage, 'error');
      }
    })
  }

  private fetchCategories() {
    this.categoryService.getSubcategoryProductRelated().subscribe({
      next: (categories) => {
        this.categories = categories.data.filter((c: Category) => !!c.children && c.children.length >= 1);
        this.selectedCat = this.categories[0];
      },
      error: (error) => {
        const errorMessage = typeof error === 'string' ? error : error.error.errors[0];
        this.toastService.displayToast(errorMessage, 'error');
      }
    })
  }

  private orderNextEvents(events: ApiResponse<Seminar[]>) {
    const today = new Date;
    this.events = events.data.filter((e: Seminar) => new Date(e.eventStart) >= today)
      .sort((a: Seminar, b: Seminar) => new Date(a.eventStart).getTime() - new Date(b.eventStart).getTime());
    if (this.events.length === 0) {
      this.isLoaded = true;
    }
    this.addCityToEvents();
  }

  private addCityToEvents() {
    forkJoin(
      this.events.map(event => {
        return this.locationService.getInfoByCityId(event.location.locationId);
      })
    ).subscribe(responses => {
      responses.forEach((res, index) => {
        this.events[index].cityTitle = res.data.cityTitle;
        this.events[index].stateShort = res.data.stateShort;
        this.isLoaded = true;
      });
    });
  }

  selectCategory(item: Category) {
    this.selectedCat = item;
    this.categoryBackgroundAnimation();
  }

  private categoryBackgroundAnimation() {
    this.categoryBackground.nativeElement.classList.remove('fade-in-image');
    this.categoryBackground.nativeElement.classList.add('display-none');
    setTimeout(() => this.categoryBackground.nativeElement.classList.add('fade-in-image'));
  }

  categoryAnimation() {
    this.categoryAnimationChanged = false;

    setTimeout(() => {
      this.categoryAnimationChanged = true;
    }, 200);
  }
}
