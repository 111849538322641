import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';


const URL_CATEGORY = `${environment.common}/category`;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  getCategory(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/?PageSize=0`, { withCredentials: true });
  }

  getCategoryById(id: string): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/${id}`, { withCredentials: true });
  }

  getSubcategory(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/subcategory/?PageSize=0`, { withCredentials: true });
  }

  getSubcategoryProductRelated(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/subcategory/product/?PageSize=0`, { withCredentials: true });
  }

  getSubcategoryAllRelated(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/subcategory/all/?PageSize=0`, { withCredentials: true });
  }

  getCategoryProductRelated(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/product/?PageSize=0`, { withCredentials: true });
  }

  getSubcategoryByIdProductRelated(id: string): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/${id}/subcategory/product/?PageSize=0`, { withCredentials: true });
  }
}
