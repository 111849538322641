export const environment = {
  production: false,
  enableTestLogs: false,
  environment: "development",
  baseUrl: 'https://site-dev.sintegrasurgical.com.br/',
  url: 'https://api-dev.sintegrasurgical.com.br/users/api/v1',
  product: 'https://api-dev.sintegrasurgical.com.br/products/api/v1',
  common: 'https://api-dev.sintegrasurgical.com.br/common/api/v1',
  content: 'https://api-dev.sintegrasurgical.com.br/contents/api/v1',
  event: 'https://api-dev.sintegrasurgical.com.br/eventhubs/api/v1'
};
