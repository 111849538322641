import { CommonModule, DatePipe, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonIcon, IonSpinner } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { star } from 'ionicons/icons';
import { TranslateModule } from '@ngx-translate/core';

import { LocalizedDatePipe } from '../../utils/pipes/localized-date/localized-date.pipe';
import { UrlService } from '../../services/url/url.service';
import { Seminar } from '../../models/Seminar.model';
import { Schedule } from '../../models/Schedule';
import { Attachment } from '../../models/Attachment.model';

@Component({
  selector: 'app-card-event',
  standalone: true,
  imports: [DatePipe, UpperCasePipe, IonIcon, TranslateModule, CommonModule, LocalizedDatePipe, IonSpinner],
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss'],
})
export class CardEventComponent implements OnInit {

  @Input() item!: Seminar;
  @Output() unfavorited = new EventEmitter<any>();
  highlight!: string;
  startDate!: Date;
  unfavoriting = false;

  constructor(public urlService: UrlService) {
    addIcons({ star });
  }

  ngOnInit() {
    if (Array.isArray(this.item.pictures)) {
      const highlightedPicture = this.item.pictures.find((picture: Attachment) => picture.highlight === true);
      if (highlightedPicture) {
        this.highlight = highlightedPicture.file;
      }
    } else if (typeof this.item.pictures === 'string') {
      this.highlight = this.item.pictures;
    }

    if (this.item.schedules) {
      const dates = this.item.schedules.map((s: Schedule) => new Date(s.scheduleStart));
      this.startDate = dates[0];
      dates.forEach((d: Date) => {
        if (d < this.startDate) {
          this.startDate = d;
        }
      });
    } else {
      this.startDate = this.item.start;
    }
  }

  unfavorite(e: Event): void {
    e.stopPropagation();
    this.unfavorited.emit(this.item);
    this.unfavoriting = true;
  }
}
