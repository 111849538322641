import { Injectable } from '@angular/core';
import { MeService } from '../api/ws-user/me/me.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public code = '';
  languages: { [key: string]: string } = {
    'en-US': 'English (US)',
    'es-ES': 'Español (ES)',
    'pt-BR': 'Português (BR)',
  };

  constructor(
    private meService: MeService,
    private translate: TranslateService,
    private storage: StorageService
  ) { }

  getLanguage = (code: string): string => this.languages[code] ?? this.languages['en-US'];

  getCode = (lang: string): string =>
    Object.keys(this.languages).find(key => this.languages[key] === lang) ?? 'en-US';

  getNavigatorLangCode(): string {
    const shortCodeToFullCode: { [key: string]: string } = {
      'en': 'en-US',
      'es': 'es-ES',
      'pt': 'pt-BR',
    };

    const navigatorLang = navigator.language || (navigator as any).userLanguage;
    const fullCode = shortCodeToFullCode[navigatorLang.slice(0, 2)] || navigatorLang;
    return ['pt-BR', 'en-US', 'es-ES'].includes(fullCode) ? fullCode : 'pt-BR';
  }

  setApplicationLanguage(): void {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeCache().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.code = this.mapToFullCode(r?.data.languageCode);
          this.translate.use(this.code);
          this.storage.setLanguage(this.code);
        }
      });
    } else {
      this.code = this.getNavigatorLangCode();
      this.translate.use(this.code);
      this.storage.setLanguage(this.code);
    }
  }

  private mapToFullCode(shortCode: string): string {
    if (shortCode) {
      const shortCodeToFullCode: { [key: string]: string } = {
        'en': 'en-US',
        'es': 'es-ES',
        'pt': 'pt-BR',
      };
      return shortCodeToFullCode[shortCode] || shortCode;
    } else {
      return 'en-US';
    }
  }

  getUsersLocale(): string {
    const wn = typeof window === 'undefined' ? undefined : window.navigator;
    return wn?.languages?.find(lang => lang.includes('-')) ?? 'en-US';
  }
}
